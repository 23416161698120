import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import hamburger from "../../assets/hamburger-white.svg";
import FeedCard from "../../components/FeedCard";
import DownloadMP360 from "../Home/sections/DownloadMP360";
import { Link } from "react-router-dom";
import loader from "../../assets/loader.json";
import Lottie from "lottie-react";
import { GET_FEED } from "../../gql/gql";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { Scrollspy } from "@makotot/ghostui";
import Breadcrumb from "../../components/Breadcrumb";

function Feed() {
  const { t, i18n } = useTranslation(); 

  let categories = {
    ALL: "All",
    KHAL: "khal",
    GOLI_KHAL: "goli_khal",
    TRADITIONAL_FEED: "traditional_khal",
    MINERAL_MIXTURE: "mineral_mix"
  };

  const { loading: feedLoading, error: feedError, data: feedData } = useQuery(GET_FEED, {
    variables: { language: i18n.language, take: 1200 }, 
    fetchPolicy: "no-cache"
  });

  if (feedError) {
    console.log(feedError);
  }

  const sectionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  const breadcrumbs = [
    { name: "Home", url: "/" },
    { name: "Feed", url: "/feed" },
  ];
  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <Helmet>
        <title>
          ऑनलाइन पशु आहार खरीदें | Buy Cattle Feed Online| Merapashu360
        </title>

        <meta
          name="description"
          content="Buy Quality Cattle Feed at MeraPashu360 Online Store, ऑनलाइन पशु आहार की सबसे बड़ी दुकान. आप कच्ची बिनोला खल, सरसो की पक्की खल और दूसरे पशु आहार खरीद सकते हैं"
        />
        <link rel="canonical" href="https://merapashu360.com/feed/" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://merapashu360.com/",
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Cattle Feed",
                "item": "https://merapashu360.com/feed/",
              },
            ],
          })}
        </script>
      </Helmet>
      {feedLoading ? (
        <Lottie animationData={loader} loop={true} />
      ) : (
        <>
          <div className="feed">
            <div className="feed__breadcrumb">
              <a href="/">
                {t("home")} {">"}{" "}
              </a>
              <a
                href="/feed
              "
              >
                <span className="green">{t("buy_cattle_feed")}</span>
              </a>
            </div>
            <Scrollspy sectionRefs={sectionRefs}>
              {({ currentElementIndexInViewport }) => (
                <>
                  <div className="feed__stick">
                    <div className="feed__filter-mob">
                      <Link to="/feed">
                        <div
                          className="feed__filter-mob--lable"
                          id={categories.ALL}
                        >
                          <p>{t(categories.ALL)}</p>
                        </div>
                      </Link>

                      {Object.entries(categories)
                        .filter(([key]) => key !== "ALL")
                        .map(([key, value], i) => (
                          <Link to={`/feed/${value}`} key={i}>
                            <div className="feed__filter-mob--lable" id={value}>
                              <p>{t(value)}</p>
                            </div>
                          </Link>
                        ))}
                    </div>
                  </div>

                  <div className="feed__container">
                    <div className="feed__filter-container">
                      <div className="feed__filter-subcontainer">
                        <div className="feed__filter--header">
                          <img src={hamburger} alt="Hamburger" />
                          <p>BROWSE CATEGORY</p>
                        </div>

                        <div className="feed__filter--category">
                          <Link to="/feed">
                            <p
                              className={
                                currentElementIndexInViewport === -1
                                  ? "feed__filter--active"
                                  : "feed__filter--lable"
                              }
                            >
                              {t(categories.ALL)}
                            </p>
                            <img src="" alt="" className="feed__filter--icon" />
                          </Link>
                        </div>

                        {Object.entries(categories).map(([key, value], i) => {
                          if (key === "ALL") {
                            return null;
                          }
                          return (
                            <div key={i} className="feed__filter--category">
                              <Link to={`/feed/${value}`}>
                                <p
                                  className={
                                    currentElementIndexInViewport === i
                                      ? "feed__filter--active"
                                      : "feed__filter--lable"
                                  }
                                >
                                  {t(value)}
                                </p>
                                <img
                                  src=""
                                  alt=""
                                  className="feed__filter--icon"
                                />
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="feed__list-container">
                      {Object.entries(categories).map(([key, value], i) => {
                        if (key === "ALL") {
                          return null;
                        }
                        return (
                          <section
                            key={i}
                            ref={sectionRefs[5]}
                            className="feed__category"
                            id={`aahar${key}`}
                          >
                            <section className="feed__title">
                              {t(value)}
                            </section>
                            <div className="feed__list">
                              {!feedLoading &&
                                feedData.feedAll &&
                                feedData.feedAll
                                  .filter(
                                    (feed: any) => feed.subCategory === key
                                  )
                                  .map((feed: any, i: number) => (
                                    <Link
                                      key={i}
                                      to={
                                        feed?.seo?.urlSlug
                                          ? `/feed/${feed?.seo?.urlSlug}`
                                          : feed.id
                                      }
                                      style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                      }}
                                    >
                                      <FeedCard
                                        id={feed?.id}
                                        vendor={feed.vendor}
                                        name={feed.name}
                                        defaultName={feed.defaultName}
                                        weight={feed.variants[0]?.weight}
                                        mrp={feed.variants[0]?.mrp}
                                        discountedPrice={
                                          feed.variants[0]?.price
                                        }
                                        tags={feed.tags}
                                        sku={feed.variants[0]?.sku}
                                        images={feed.media}
                                      />
                                    </Link>
                                  ))}
                            </div>
                          </section>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </Scrollspy>
          </div>

          <DownloadMP360 />
        </>
      )}
    </>
  );
}

export default Feed;
