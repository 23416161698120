import React, { useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import hamburger from "../../assets/hamburger-white.svg";
import FeedCard from "../../components/FeedCard";
import DownloadMP360 from "../Home/sections/DownloadMP360";
import { NavHashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import loader from "../../assets/loader.json";
import Lottie from "lottie-react";
import { GET_FEED } from "../../gql/gql";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Scrollspy } from "@makotot/ghostui";
import Breadcrumb from "../../components/Breadcrumb";

function FeedKhal() {
  const { t, i18n } = useTranslation(); 

  let categories = {
    ALL: "All",
    KHAL: "khal",
    GOLI_KHAL: "goli_khal",
    TRADITIONAL_FEED: "traditional_khal",
    MINERAL_MIXTURE: "mineral_mix"
  };
  const { loading: feedLoading, error: feedError, data: feedData } = useQuery(GET_FEED, {
    variables: { language: i18n.language, take: 1200 }, 
    fetchPolicy: "no-cache"
  });

  if (feedError) {
    console.log(feedError);
  }

  const sectionRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  const breadcrumbs = [
    { name: "Home", url: "/" },
    { name: "Feed", url: "/feed" },
  ];

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <Helmet>
        <title>
          Binola Khal For Cattle Feed (Cow/Buffalo) at Best Price in Haryana, India
        </title>

        <meta
          name="description"
          content="Order top-quality Binola Khal & Sarso Ki Khal for cow/buffalo/cattle feed at unbeatable prices in Haryana from MeraPashu360. पशु के लिए बिनोला खल, सरसो खल खरीदिये।"
        />
        <link rel="canonical" href="https://merapashu360.com/feed/khal" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://merapashu360.com/",
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Cattle Feed",
                "item": "https://merapashu360.com/feed/",
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Khal",
                "item": "https://merapashu360.com/feed/khal",
              },
            ],
          })}
        </script>
      </Helmet>
      {feedLoading ? (
        <Lottie animationData={loader} loop={true} />
      ) : (
        <>
          <div className="feed">
            <div className="feed__breadcrumb">
              <a href="/">
                {t("home")} {">"}{" "}
              </a>
              <a href="/feed">
                <span className="green">{t("buy_cattle_feed")}</span>
              </a>
            </div>
            <Scrollspy sectionRefs={sectionRefs}>
              {({ currentElementIndexInViewport }) => (
                <>
                  <div className="feed__stick">
                    <div className="feed__filter-mob">
                      <Link to="/feed">
                        <div
                          className="feed__filter-mob--lable"
                          id={categories.ALL}
                        >
                          <p>{t(categories.ALL)}</p>
                        </div>
                      </Link>

                      {Object.entries(categories)
                        .filter(([key]) => key !== "ALL")
                        .map(([key, value], i) => (
                          <Link to={`/feed/${value}`} key={i}>
                            <div className="feed__filter-mob--lable" id={value}>
                              <p>{t(value)}</p>
                            </div>
                          </Link>
                        ))}
                    </div>
                  </div>

                  <div className="feed__container">
                    <div className="feed__filter-container">
                      <div className="feed__filter-subcontainer">
                        <div className="feed__filter--header">
                          <img src={hamburger} alt="Hamburger" />
                          <p>BROWSE CATEGORY</p>
                        </div>

                        <div className="feed__filter--category">
                          <Link to="/feed">
                            <p
                              className={
                                currentElementIndexInViewport === -1
                                  ? "feed__filter--active"
                                  : "feed__filter--lable"
                              }
                            >
                              {t(categories.ALL)}
                            </p>
                            <img src="" alt="" className="feed__filter--icon" />
                          </Link>
                        </div>

                        {Object.entries(categories).map(([key, value], i) => {
                          if (key === "ALL") {
                            return null;
                          }
                          return (
                            <div key={i} className="feed__filter--category">
                              <Link to={`/feed/${value}`}>
                                <p
                                  className={
                                    currentElementIndexInViewport === i
                                      ? "feed__filter--active"
                                      : "feed__filter--lable"
                                  }
                                >
                                  {t(value)}
                                </p>
                                <img
                                  src=""
                                  alt=""
                                  className="feed__filter--icon"
                                />
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="feed__list-container">
                      <section
                        ref={sectionRefs[1]}
                        className="feed__category"
                        id={`aahar${Object.keys(categories)[1]}`}
                      >
                        <section className="feed__title">
                          {t(Object.values(categories)[1])}
                        </section>
                        <div className="feed__list">
                          {!feedLoading &&
                            feedData.feedAll &&
                            feedData.feedAll
                              .filter(
                                (feed: any) =>
                                  feed.subCategory ===
                                  Object.keys(categories)[1]
                              )
                              .map((feed: any, i: number) => (
                                <Link
                                  key={i}
                                  to={
                                    feed?.seo?.urlSlug
                                      ? `/feed/${feed?.seo?.urlSlug}`
                                      : feed.id
                                  }
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  <FeedCard
                                    id={feed?.id}
                                    vendor={feed.vendor}
                                    name={feed.name}
                                    defaultName={feed.defaultName}
                                    weight={feed.variants[0]?.weight}
                                    mrp={feed.variants[0]?.mrp}
                                    discountedPrice={feed.variants[0]?.price}
                                    tags={feed.tags}
                                    sku={feed.variants[0]?.sku}
                                    images={feed.media}
                                  />
                                </Link>
                              ))}
                        </div>
                      </section>
                    </div>
                  </div>
                </>
              )}
            </Scrollspy>
          </div>

          <DownloadMP360 />
        </>
      )}
    </>
  );
}

export default FeedKhal;
