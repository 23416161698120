import Button from "../../../components/Button/Button";
import PERCENT from "../../../assets/g3093.svg";
import { Trans, useTranslation } from "react-i18next";

function Hero() {
  let { t } = useTranslation();
  return (
    <div className="hero">
      <div className="hero__container">
      <a href="https://mp360.app/home" className="hero_invisible-box"></a>
        <p className="hero__subtitle subheading hindi green">
          <a
            href="https://mp360.app/home"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {t("buy_best_feed")}
          </a>
        </p>
        <h1 className="hero__heading hindi">
          <Trans
            i18nKey="hero_title"
            components={{
              a1: (
                <a
                  href="https://mp360.app/buffalo"
                  className="green"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                />
              ),
              a2: (
                <a
                  href="https://mp360.app/home"
                  className="green"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                />
              ),
            }}
          />
        </h1>

        <div className="hero__quote">
          <img src={PERCENT} alt="percent" className="hero__quote--icon" />
          <p className="hero__quote--text hindi">
            <Trans
              i18nKey="hero_subtitle"
              components={{
                span: <span/>,
              }}
            />
          </p>
        </div>

        <div className="hero__button">
          {localStorage.getItem("i18nextLng") === "en" ? (
            <Button
              primary
              text="DOWNLOAD APP & GET ₹100"
              playStore={true}
              to="https://play.google.com/store/search?q=merapashu360&c=apps"
            />
          ) : (
            <Button
              primary
              text={t("get_100_app_download")}
              playStore={true}
              to="https://mp360.app/home"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Hero;
