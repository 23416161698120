import React, { useEffect } from "react";


const ChatBot = () => {
  const dfMessengerStyles = `
    df-messenger {
        z-index: 999;
        position: fixed;
        --df-messenger-font-color: #000;
        --df-messenger-font-family: Google Sans;
        --df-messenger-chat-background: #f3f6fc;
        --df-messenger-message-user-background: #d3e3fd;
        --df-messenger-message-bot-background: #fff;
        bottom: 0;
        right: 0;
        top: 0;
        width: 100%;
    }
  `;

  function parseJwt (token:any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
  function getChatAuthToken() {
      const cookieName = 'chat-auth-token';
      const cookies = document.cookie.split(';');
      for (let cookie of cookies) {
          const [name, value] = cookie.trim().split('=');
          if (name === cookieName) {
              console.log('Chat auth token:', value);
              return value;
          }
      }
      console.log('Chat auth token not found');
      return null;
  }

  async function registerSession(sessionId:any){
      const token = getChatAuthToken()
      const url = process.env.REACT_APP_BFF_URL

      if(url){
      await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
          body: `{"query":"mutation RegisterChatBotSession ($sessionId: String!) {RegisterChatBotSession(sessionId: $sessionId)}","variables":{"sessionId":"${sessionId}"}}`
      })
    }
  }
  

  useEffect(() => {
    const script = document.querySelector(
      'script[src="https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js"]'
    );

    if (!script) {
      const script = document.createElement("script");
      script.src =
        "https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js";
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        console.log("script loaded successfully.");
      };


      window.addEventListener('df-messenger-loaded', () => {
        console.log('df-messenger-loaded');
        const dfMessenger = document.querySelector('df-messenger') as any
        
        dfMessenger.startNewSession({ retainHistory: false })
        const token = getChatAuthToken()
        if (token) {
            registerSession(dfMessenger?.sessionId)
            const decodedToken = parseJwt(token)
            const name = decodedToken.name
            if (name) {
    
              const imagePayload = [
              {
                "type": "image",
                "rawUrl": "https://allmemetemplates.wordpress.com/wp-content/uploads/2020/02/img_20200630_1408394102238748164468880.jpg?w=1024",
                "accessibilityText": "Hemlo",
              }
              ]
              dfMessenger?.renderCustomCard(imagePayload)
              const payload = [
                {
                  "type": "info",
                  "title": `Howdy ${name}!`,
                  "subtitle": "Greetings from Mhoon360",
                  "image": {
                    "rawUrl": 'https://allmemetemplates.wordpress.com/wp-content/uploads/2020/02/img_20200630_1408394102238748164468880.jpg?w=1024'
                  }
                }
              ];
              dfMessenger?.renderCustomCard(payload);
              // dfMessenger.renderCustomText(`Welcome ${name}!`, 'bot');
            }
            dfMessenger?.sendRequest('query', 'Namaste');
        }
    
        // dfMessenger.renderCustomText(`chat-auth-token = ${token} sessionId = ${dfMessenger.sessionId}`, 'bot');
    
    
      });
    } else {
      console.log("script already loaded.");
    }
  

  }, []);
  

  return (
    <div>
    <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
        Main Hoon 360
      </h1>
    <style>{dfMessengerStyles}</style>

    <df-messenger
      location="asia-south1"
      project-id="hubemployee-189ad"
      agent-id="e7d8ce86-a390-4bcb-b4eb-91680830b9aa"
      language-code="en"
      max-query-length="-1"
      allow-feedback="all">
      <df-messenger-chat
      chat-title="Mhoon360">
      </df-messenger-chat>
    </df-messenger>

    </div>
  );
};

export default ChatBot;
