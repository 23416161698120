import React from "react";
import { Helmet } from "react-helmet-async";
import { PHONE_NUMBER } from "../constants";

function CommercePolicy() {
  return (
    <>
      <Helmet>
        <title>Commerce Policy | MeraPashu360</title>
        <meta name="description" content="Commerce Policy for MeraPashu360." />
      </Helmet>
      <div className="terms--root">
        <div className="policy-section" data-item="rte-content">
          <ul style={{listStyle:"none"}}>
            <li>
              At MeraPashu360, Our motto is:{" "}
              <strong>Swastha Pashu, Samruddh Kisan.</strong>
            </li>
            <li>
              Decision of the management will be final in case of any disputes.
            </li>
          </ul>

          <h2>Shipping Policy</h2>
          <ol>
            <li>
              The delivery date of the order will be based on the pincode of the
              shipping address, and it will be confirmed after the order is
              successfully placed.
            </li>
            <li>
              The delivery date will be informed to the customer exclusively via
              App.
            </li>
            <li>
              Shipping / delivery charges will be explicitly mentioned in the
              order.
            </li>
            <li>
              Shipping charges will depend on the pincode of the shipping
              address and the volume of goods ordered.
            </li>
            <li>
              If the delivery charges are not mentioned in the order, then
              MeraPashu360 will provide free delivery.
            </li>
            <li>There are no hidden charges / additional costs of shipping.</li>
            <li>Charges mentioned on the order are final.</li>
            <li>
              MeraPashu360 provides shipping in India only. Please ensure that
              your delivery address is in India only.
            </li>
            <li>
              If MeraPashu360 cannot ship the order within 5 days of order
              confirmation, then the order will be canceled automatically and a
              refund will be issued to the customer.
            </li>
            <li>
              Availability of CoD depends on the sole discretion of
              MeraPashu360.
            </li>
          </ol>

          <h2>Returns Policy</h2>
          <ol>
            <li>
              Livestock feed is an edible and perishable item, and hence goods once
              sold will not be returned.
            </li>
            <li>We do not accept the return or exchange of open bags.</li>
            <li>
              However, if you encounter any issues with the quality of the
              product, we encourage you to reach out to us within 3 days of your
              purchase.
            </li>
            <li>
              Keep the original invoice for your records and share it with the
              customer care team for any returns / exchange requests.
            </li>
          </ol>

          <h2>Refunds and Cancellations Policy</h2>
          <ol>
            <li>
              In the event, you are displeased with our products or services, we
              will, at our sole discretion, refund your money if the reasons
              provided by you are genuine to our satisfaction and proven after
              investigation.
            </li>
            <li>
              In case of dissatisfaction with our services, you have the liberty
              to cancel the orders and request a refund from us. Our policy for
              the cancellation and refund will be as follows:
            
            <ol type="a">
              <li>
                You may cancel your order free of charge up to 1 (one) day
                before the order is dispatched for delivery.
              </li>
              <li>
                You will be refunded the amount paid for such an order, if any.
                Processing of the refund may take up to 7 (seven) working days.
              </li>
              <li>
                Your refund will be made to the same account from which the
                payment was originally made.
              </li>
              <li>
                For ‘Cash on Delivery’ products, no refund will be provided.
              </li>
              <li>
                To initiate the refund, please contact our customer care at our
                customer care number <strong>{PHONE_NUMBER}</strong>.
              </li>
            </ol>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}

export default CommercePolicy;
