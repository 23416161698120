import React from 'react';
import { useTranslation } from 'react-i18next';
import impact1 from "../assets/impactImages/1.webp";
import impact2 from "../assets/impactImages/2.webp";
import impact3 from "../assets/impactImages/3.webp";
import impact4 from "../assets/impactImages/4.webp";
import impact5 from "../assets/impactImages/5.webp";
import impact6 from "../assets/impactImages/6.webp";
import logo1 from '../assets/impactImages/Industy.webp';
import logo2 from '../assets/impactImages/poverty.webp';
import logo3 from '../assets/impactImages/decent.webp';
import logo4 from '../assets/impactImages/climate.webp';
import logo5 from '../assets/impactImages/gender.webp';
import logo6 from '../assets/impactImages/health.webp';

const impactsData = [
  {
    percentage: "impact_text1",
    image: impact1,
    logo: logo1
  },
  {
    percentage: "impact_text2",
    image: impact2,
    logo: logo2
  },
  {
    percentage: "impact_text3",
    image: impact3,
    logo: logo3
  },
  {
    percentage: "impact_text4",
    image: impact4,
    logo: logo4
  },
  {
    percentage: "impact_text5",
    image: impact5,
    logo: logo5
  },
  {
    percentage: "impact_text6",
    image: impact6,
    logo: logo6
  }
];


function ImpactSection() {
  const { t } = useTranslation();

  return (
    <section className="impactLayout">
      <div className="impactPage">
        <div className="impactPage__container">
          <div className="impactPage__content">
            <p className="impactPage__heading">{t('impact_subheading')}</p>
            <div className="impact-cards">
              {impactsData.map((impact, index) => (
                <div className="impact-card" key={index}>
                     <div className="impact-percentage-container">
                    <div className="impact-imagelogo">
                      <img src={impact.logo} alt="impact logo" />
                    </div>
                  <p className="impact-percentage" dangerouslySetInnerHTML={{ __html: t(impact.percentage) as string }}></p>
                  </div>
                  <div className="impact-image-wrapper">
                    <img src={impact.image} alt="impact" />
                  </div>
                  
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ImpactSection;
