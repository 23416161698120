import { gql } from '@apollo/client';

export const GET_ESTIMATED_SYC_PRICE = gql`
  query GetSycEstimate(
    $animalType: String!
    $breed: String!
    $readyMilk: QuantityInput!
    $calfGender: String
    $heightInInches: Float
    $lactationNumber: Float!
    $daysSinceLastParturition: Float!
    $pincode: Float
    $phoneNumber: String!
    $otherComments: String
    $captchaToken: String!
  ) {
    getSycEstimate(
      animalType: $animalType
      breed: $breed
      readyMilk: $readyMilk
      calfGender: $calfGender
      heightInInches: $heightInInches
      lactationNumber: $lactationNumber
      daysSinceLastParturition: $daysSinceLastParturition
      pincode: $pincode
      phoneNumber: $phoneNumber
      otherComments: $otherComments
      captchaToken: $captchaToken
    ) {
      lowerBound {
        currencyCode
        amount
      }
      upperBound {
        currencyCode
        amount
      }
    }
  }
`;

const GET_CATTLE = gql`
  query animals(
    $take: Int
    $breed: [String!]
    $calving: [Int!]
    $milkFilter: MilkingFilterDto
    $priceFilter: PriceFilterDto
    $parturitionDaysFilter: ParturitionDaysFilterDto
    $hub: [String!]
  ) {
    animals(
      take: $take
      breed: $breed
      calving: $calving
      milkFilter: $milkFilter
      priceFilter: $priceFilter
      parturitionDaysFilter: $parturitionDaysFilter
      hub: $hub
    ) {
      id
      tag
      animalType
      milkRecords {
        recordDate
        weight {
          value
          uom
        }
      }
      averageMilkYield {
        recordDate
        weight {
          value
          uom
        }
      }
      averageMilkQuality {
        recordDate
        fat
        snf
      }
      expectedMilkYield {
        recordDate
        weight {
          value
          uom
        }
      }
      pregnancyInfo {
        calving
        isPregnant
        months
      }
      accompanyingChildren {
        gender
      }
      currentLocation {
        id
        name
        type
        address {
          name
          firstLine
          secondLine
          city
          district
          pin
          state
          country
        }
        geoLocation {
          longitude
          latitude
        }
      }
      price {
        currencyCode
        amount
      }
      mrp {
        currencyCode
        amount
      }
      breed
      parturitionDate
      mediaRecords {
        url
        of
      }
    }
  }
`;
export default GET_CATTLE;

export const GET_FEED = gql`
query FeedAll($language: String!, $take: Float!) {
  feedAll(language: $language, take: $take) {
    id
    name
    defaultName
    vendor
    media {
      of
      contentType
      url
      _id
    }
    variants {
      _id,
      serviceability,
      sku,
      name,
      status,
      weight{
          value,
          uom,
      },
      description,
 
      media{
          of,
          contentType,
          url,
          _id,
      },
      price {
          currencyCode,
          amount,
      },
      mrp {
          currencyCode,
          amount,
      },
      stockStatus
  }
  
    tags {
      key
      value
    }
    subCategory
    
    defaultName
    qualityTier
    composition {
      description
      mediaCollection {
        name
        url
        contentType
      }
    }
    dosage {
      description
      mediaCollection {
        name
        url
        contentType
      }
    }
    benefits {
      description
      mediaCollection {
        name
        url
        contentType
      }
    }
    seo {
      title
      description
      urlSlug
    }
  }
}
`;

export const GET_DATA = gql`
    query {
      siteData {
        testimonials {
          url
          rating
          name
          text
          village
          title
        }
        weOffer {
          deliveredBaffaloCount
          deliveredFeedCount
          qcCount
          rating
          totalDownloads
        }
        youtubeVideos {
          url
          title
        }
        stats {
          kisanServed
          villDelivered
          pincodeServed
          katteSold
          appDownloads
          buffaloSold
          serviceablePincodes
        }
      }
    }
  `;

export const GET_PRODUCT_BY_SKU = gql`
  query getProductBySku($sku: String!, $language: String!) {
    feed(sku: $sku, language: $language) {
      id
      name
      defaultName
      vendor
      media {
        of
        contentType
        url
        _id
      }
      variants {
        _id,
        serviceability,
        sku,
        name,
        status,
        weight{
            value,
            uom,
        },
        description,
   
        media{
            of,
            contentType,
            url,
            _id,
        },
        price {
            currencyCode,
            amount,
        },
        mrp {
            currencyCode,
            amount,
        },
        stockStatus
    }
    
      tags {
        key
        value
      }
      subCategory
      
      defaultName
      qualityTier
      composition {
        description
        mediaCollection {
          name
          url
          contentType
        }
      }
      dosage {
        description
        mediaCollection {
          name
          url
          contentType
        }
      }
      benefits {
        description
        mediaCollection {
          name
          url
          contentType
        }
      }
      seo {
        title
        description
        urlSlug
      }
    }
  }
`;